
$dropdown-main-color: rgba(46, 46, 46, 0.4);
$dropdown-basic-height: 40px;
$dropdown-basic-border-width: 2px;
$dropdown-basic-border-radius: 2px;

*, *:before, *:after {
  box-sizing: inherit;
}

#root {
  height: 100%;
}

html {
  height: 100%;
  box-sizing: border-box;
}

h2 {
  margin-top: 60px;
  margin-bottom: 10px;
}

body {
  background: #faf8ef;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #776E65;
  height: 100%;
}

p {
  line-height: 1.65;
}

a {
  color: #776e65;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}

.container {
  margin: auto;
  min-width: 320px;
}

.header {
  align-items: center;
  display: flex;
  margin-bottom: 20px;

  .title { 
    align-items: baseline;
    font-weight: 700;
    font-size: 2rem;
    line-height: 2rem;
    display: flex;
    flex: 1;
  }

  span {
    color: #f2b179;
    font-size: 1.5rem;
  }
}

.score-panel {
  align-items: center;
  background-color: #bbada0;
  border-radius: 3px;
  color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 1.5625rem;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  padding: 10px;
  margin: 5px;

  label {
    font-size: 0.8125rem;
    text-transform: uppercase;
  }
}

.scores-container {
  display: flex;
  flex: 1;
}

.button {
  background-color: #8f7a66;
  border-radius: 3px;
  border: none;
  color: #f9f6f2;
  font-weight: 700;
  font-size: 18px;
  line-height: 42px;
  width: 100%;

  &:active {
    position: relative;
  }
}

.button-wrapper {
  margin-bottom: 20px;
}

.dropdown {
  position: relative;
  position: inline-block;

  .dropdown_caret {
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 12px;
    color: $dropdown-main-color;
    cursor: pointer;
    z-index: 1;
    pointer-events: none;
  }

  .dropdown_select {
    border-radius: $dropdown-basic-border-radius;
    border: $dropdown-basic-border-width solid $dropdown-main-color;
    box-sizing: border-box;
    font-family: inherit;
    font-size: 16px;
    height: $dropdown-basic-height;
    margin: 4px 0;
    padding: 0 27px 0 10px; 
    cursor: pointer;
    text-overflow: ellipsis;
    outline: none;
    background: white;
    width: 100%;
    max-width: 150px;
  }

  .label {
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
  }
}

form {
  width: 100%;

  input {
    margin-bottom: 10px;
  }
}

input {
  -webkit-tap-highlight-color: transparent;
  border-radius: 2px;
  border: 1px solid #f59563;
  font-family: 'Roboto', sans-serif;
  font-size: 1.125rem;
  padding: 0.75rem;
  width: 100%;
}

.address {
  color: #776e65;
  margin-bottom: 5px;
}

.statistics {
  align-items: center;
  display: flex;
}

.statistic {
  margin-right: 10px;
}

.history-item {
  border-bottom: 1px solid #d8d4d0;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.board-footer {
  text-align: right;
}

.error {
  color: #f59563;
  margin-bottom: 5px;
}

.notice {
  .button {
    background-color: #F65E3B;
  }
}

.important {
  text-transform: uppercase;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    flex-direction: column;
  }
}

.balance {
  font-weight: 700;
}

.pool-balance {
  margin-bottom: 10px;
}

.open-modal {
  appearance: none;
  background: #edc53f;
  border-radius: 3px;
  border: none;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
  height: 40px;
  padding: 10px;
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
}

.notice {
  appearance: none;
  background: #f65e3b;
  border-radius: 3px;
  border: none;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
  padding: 10px;
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
}
.info {
  appearance: none;
  background: #edc53f;
  border-radius: 3px;
  border: none;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
  padding: 10px;
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
  text-decoration: none;
}
